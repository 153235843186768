import {mapMutations, mapActions, mapGetters} from "vuex";

//sections

export default {
    name: "notification",
    components: {},
    data() {
        return {}
    },
    created() {
    },
    mounted() {

    },
    watch: {},
    computed: {
        ...mapGetters({})
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({}),
    }
}
