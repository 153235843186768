import {mapMutations, mapActions, mapGetters} from "vuex";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

//sections

export default {
    name: "create-legal",
    components: {},
    data() {
        return {
            createLegalPayload: {
                name: '',
                juridical_address: '',
                real_address: '',
                site: '',
                facebook: '',
                instagram: '',
                twitter: '',
                youtube: ''
            },
            codeDuringCreate: '',
            fullType: {title: '', id: ''}
        }
    },
    validations: {
        createLegalPayload: {
            name: {
                required,
                minLength: minLength(2)
            },
            juridical_address: {
                required,
                minLength: minLength(5)
            },
            real_address: {
                required,
                minLength: minLength(5)
            }
        },
        codeDuringCreate: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(10)
        },
        fullType: {
            title: {required}
        }
    },
    created() {
        this.fetchOwnershipTypes()
    },
    mounted() {

    },
    watch: {},
    computed: {
        ...mapGetters({
            type: 'profile/ownershipTypes',
            isButtonLoader: 'profile/isLoadingButtonLoader',
        }),
        nameErrors() {
            let error = [];
            if (!this.$v.createLegalPayload.name.$dirty) {
                return error;
            }
            if (!this.$v.createLegalPayload.name.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.createLegalPayload.name.minLength) {
                error.push(this.$t('validation.minLength', {'size': 2}));
            }
            return error;
        },
        codeErrors() {
            let error = [];
            if (!this.$v.codeDuringCreate.$dirty) {
                return error;
            }
            if (!this.$v.codeDuringCreate.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.codeDuringCreate.minLength) {
                error.push(this.$t('validation.minLength', {'size': 8}));
            }
            if (!this.$v.codeDuringCreate.maxLength) {
                error.push(this.$t('validation.maxLength', {'size': 10}));
            }
            /*todo check - 9*/
            return error;
        },
        legalAddressErrors() {
            let error = [];
            if (!this.$v.createLegalPayload.juridical_address.$dirty) {
                return error;
            }
            if (!this.$v.createLegalPayload.juridical_address.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.createLegalPayload.juridical_address.minLength) {
                error.push(this.$t('validation.minLength', {'size': 5}));
            }
            return error;
        },
        addressErrors() {
            let error = [];
            if (!this.$v.createLegalPayload.real_address.$dirty) {
                return error;
            }
            if (!this.$v.createLegalPayload.real_address.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.createLegalPayload.real_address.minLength) {
                error.push(this.$t('validation.minLength', {'size': 5}));
            }
            return error;
        },
        fullTypeErrors() {
            let error = [];
            if (!this.$v.fullType.title.$dirty) {
                return error;
            }
            if (!this.$v.fullType.title.required) {
                error.push(this.$t('validation.required'))
            }
            return error;
        },
    },
    methods: {
        ...mapMutations({
            changeUserData: 'profile/CHANGE_USER_DATA'
        }),
        ...mapActions({
            createLegalUser: 'profile/CREATE_LEGAL_USER',
            fetchOwnershipTypes: 'profile/GET_OWNERSHIP_TYPES',

        }),
        submitLegalForm() {
            this.$v.$touch()
            if (this.$v.$anyError) return
            const finalData = {...this.createLegalPayload, type_id: this.fullType.id}

            finalData.code = String(this.codeDuringCreate)

            this.createLegalUser(finalData)
                .then(() => {
                    this.changeUserData(true)
                    this.$emit('close-modal')
                })
        }
    }
}
